/**
 * Created by Administrator on 2019/10/7.
 */
 import { request } from '../utils/request'
 import serviceInterface from '../api/serviceInterface'
 
 /**
  * 生成支付订单，获取支付二维码
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const getPayERCode = (params) => {
     return request({
         url: serviceInterface.pay.getERCode,
         params: params
     })
 }
 
 /**
  * 生成支付订单，获取支付宝二维码
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const getPayZFBERCode = (params) => {
     return request({
         url: serviceInterface.pay.getZFBERCode,
         params: params
     })
 }
 
 /**
  * 虎皮椒充值，获取支付二维码
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const HPJCharge = (params) => {
     return request({
         url: serviceInterface.recharge.pfjRecharge,
         params: params
     })
 }
 
 /**
  * 生成vip支付订单，获取支付二维码
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const getVIPPayERCode = (params) => {
     return request({
         url: serviceInterface.pay.getVIPERCode,
         params: params
     })
 }
 
 /**
  * 查询支付状态，支付成功则跳转
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const queryPayStatus = (params) => {
     return request({
         url: serviceInterface.pay.queryStatus,
         params: params,
         flag:'flag'
     })
 }
 
 /**
  * 查询支付状态，支付成功则跳转
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const queryPayStatusZFB = (params) => {
     return request({
         url: serviceInterface.pay.queryStatusZFB,
         params: params,
         flag:'flag'
     })
 }
 
 /**
  * 查询支付状态，支付成功则跳转
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const queryPayStatusNew = (params) => {
     return request({
         url: serviceInterface.pay.queryStatusNew,
         params: params,
         flag:'flag'
     })
 }
 
 /**
  * 重置支付状态
  * @param params
  * @returns {*|Promise|Promise<unknown>|undefined}
  */
 export const updatePayStatus = (params) => {
     return request({
         url: serviceInterface.pay.updateStatus,
         params: params
     })
 }
 
 